import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export default function Hero(props) {
  const data = useStaticQuery(graphql`
    {
      file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "joe_sanchez_jr-profile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="hero">
      <div className="hero__left">
        <div>
          <h1 className="hero__title">
            Photographer
            <br />
            &amp; Front-End Developer
          </h1>
          <p>
            Focusing on the stories found at the intersections of faith,
            sexuality, and a Latinx identity
          </p>
        </div>
        <p>
          Available for hire 
          <br />
          <a
            href="https://www.joesanchezjr.dev/?utm_source=com-hero"
            target="_blank"
            rel="noopener noreferrer"
            className="white-underline-link"
          >
           View developer portfolio
          </a>
        </p>
      </div>

      <div className="hero__right">
        <Img
          fluid={data.file.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="46% 25%"
          className="hero__profile-image"
        />
      </div>
    </section>
  )
}
